import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  list: [],
  current: {},
};

const investmentReportsSlice = createSlice({
  name: "investmentReports",
  initialState,
  reducers: {
    setInvestmentReportsList: (state, { payload }) => ({
      ...state,
      list: [...payload.reports],
    }),
    setCurrentInvestmentReports: (state, { payload }) => ({
      ...state,
      current: { ...payload.report },
    }),
    resetInvestmentReportsState: () => ({ list: [], current: {} }),
  },
});

export const {
  setInvestmentReportsList,
  setCurrentInvestmentReports,
  resetInvestmentReportsState,
} = investmentReportsSlice.actions;
export default investmentReportsSlice.reducer;
