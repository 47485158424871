import { createAsyncThunk } from "@reduxjs/toolkit";
import { getInvestments } from "../api/investmentsApi";
import { setInvestmentsList } from "../slices/investmentsSlice";
import { handleError } from "../slices/errorsSlice";
import { startLoading, endLoading } from "../slices/loadingSlice";
import { setInvestmentReportsList } from "../slices/investentsReportsSlice";
import { requestReportsApi } from "../api/reportsRequestApi";

export const getInvestmentsReportsList = createAsyncThunk(
  "investments/financialReports/getList",
  async ({ query, type = "financiero" }, { getState, dispatch }) => {
    const { token } = getState().auth;

    dispatch(startLoading());

    getInvestments(token, query)
      .then((response) =>
        response.status === 200 ? response.json() : Promise.reject(response)
      )
      .then((data) => {
        dispatch(setInvestmentsList({ investments: data.investments }));
        return data.investments;
      })
      .then((investments) => {
        let projectReportList = [];
        investments?.forEach((element) => {
          const result = requestReportsApi(
            token,
            (query = {
              projectRef: element.projectRef._id,
            })
          );
          projectReportList.push(result);
        });
        return projectReportList;
      })
      .then(async (result) => {
        const data = await Promise.allSettled(result);
        return data;
      })
      .then((result) => {
        let allReports = [];
        result?.forEach((element) => {
          allReports.push(...element.value);
        });
        const typeReports = allReports.filter((report) => report.type === type);
        dispatch(setInvestmentReportsList({ reports: typeReports }));
      })

      .catch((err) => dispatch(handleError(err)))
      .finally(() => dispatch(endLoading()));
  }
);
