import React, { useEffect, useState } from "react";
import CardAccordion from "../../../../../../../components/containers/CardAccordion";
import {
  Box,
  Card,
  MenuItem,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import {
  Bar,
  BarChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { getTenYears } from "../../../../../../../utils/getTenyears";
import { currencyFormatter } from "../../../../../../../utils/formatUtils";

const years = getTenYears();

const ages = [
  {
    value: "Todos",
    label: "Todos",
  },
  ...years.map((data) => {
    return {
      value: data,
      label: data.toString(),
    };
  }),
];

const CustomTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    return (
      <Card sx={{ p: 1 }}>
        <Typography
          variant="caption"
          component={"p"}
          fontWeight={"bold"}
          align="center"
        >{`${payload[0].payload.name}`}</Typography>
        <Typography variant="caption" component={"p"} textAlign={"center"}>
          Fecha: {new Date(payload[0].payload.date).toLocaleDateString()}
        </Typography>
        <div>
          {payload.map((pld, index) => (
            <Typography
              variant="caption"
              component={"p"}
              textAlign={"center"}
              key={`${pld.value}-${index}`}
            >
              Capital: {currencyFormatter.format(pld.value).split(".")[0]}
            </Typography>
          ))}
        </div>
      </Card>
    );
  }

  return null;
};

function DistributionRecordBarGraph({ data }) {
  const isSmallScreen = useMediaQuery("(min-width:600px)");

  const [myData, setMyData] = useState([]);

  const handleData = (myNewData, selec) => {
    setMyData(myNewData);
  };

  useEffect(() => {
    handleData(data, "Todos");
  }, [data]);

  const [selectedAge, setSelectedAge] = useState("Todos");

  const handleChange = (event) => {
    setSelectedAge(event.target.value);
    if (event.target.value === "Todos") {
      return handleData(data, event.target.value);
    } else {
      const mySelectedData = data.filter((element) => {
        const date = new Date(element.date).getFullYear();
        // console.log({ date, selectedDate: event.target.value });
        return date === event.target.value;
      });
      handleData(mySelectedData, event.target.value);
    }
  };

  if (data.length === 0) {
    return (
      <>
        <Typography>Sin data</Typography>
      </>
    );
  }

  return (
    <CardAccordion title={"Historial Distribuciones"}>
      <Box>
        <Box display={"flex"} justifyContent={"flex-end"} px={3}>
          <Box sx={{ minWidth: 200 }}>
            <TextField
              select
              label="Fecha"
              disabled={false}
              value={selectedAge}
              onChange={handleChange}
              sx={{ py: 0 }}
            >
              {ages.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Box>
        </Box>
        <ResponsiveContainer height={isSmallScreen ? 200 : 300}>
          <BarChart
            data={myData}
            margin={{
              top: 15,
              right: 25,
              left: 25,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis
              position="middle"
              tickFormatter={(label) =>
                `${currencyFormatter.format(Number(label ?? 0)).split(".")[0]}`
              }
            />
            <Tooltip content={<CustomTooltip />} />
            <Bar
              dataKey="capital"
              barSize={`${65 / myData.length}%`}
              fill="#8884d8"
            />
          </BarChart>
        </ResponsiveContainer>
        <Typography variant="caption" component={"p"} align="end" color="gray">
          Chart con información a la fecha de la transacción
        </Typography>
      </Box>
    </CardAccordion>
  );
}

export default DistributionRecordBarGraph;
