import { Box, TableCell, TableRow, Typography } from "@mui/material";
import React from "react";
import { currencyFormatter } from "../../../../utils/formatUtils";

function ProjectsTotal({ projects }) {
  const usdTotal = projects
    .filter((element) => element.projectRef.isInvestmentUSD)
    .reduce(
      (accumulator, currentValue) => accumulator + currentValue?.capital ?? 0,
      0
    );
  const mxnTotal = projects
    .filter((element) => !element.projectRef.isInvestmentUSD)
    .reduce(
      (accumulator, currentValue) => accumulator + currentValue?.capital ?? 0,
      0
    );
  return (
    <>
      {projects.lenght < 1 ? (
        <TableRow>
          <TableCell colSpan={6}>
            <Box display={"flex"} justifyContent={"center"}>
              <Typography
                variant="caption"
                component={"p"}
                textAlign={"center"}
              >
                No hay total
              </Typography>
            </Box>
          </TableCell>
        </TableRow>
      ) : (
        <>
          <TableRow>
            <TableCell colSpan={6} sx={{ py: 2 }}></TableCell>
          </TableRow>
          {usdTotal > 0 ? (
            <TableRow>
              <TableCell
                colSpan={4}
                sx={{ textAlign: "left", fontWeight: "bold" }}
              >
                TOTAL USD :
              </TableCell>
              <TableCell sx={{ textAlign: "right", fontWeight: "bold" }}>
                {currencyFormatter.format(usdTotal).split(".")[0]}
              </TableCell>
              <TableCell></TableCell>
            </TableRow>
          ) : (
            <></>
          )}
          {mxnTotal > 0 ? (
            <TableRow>
              <TableCell
                colSpan={4}
                sx={{ textAlign: "left", fontWeight: "bold" }}
              >
                TOTAL MXN :
              </TableCell>
              <TableCell sx={{ textAlign: "right", fontWeight: "bold" }}>
                {currencyFormatter.format(mxnTotal).split(".")[0]}
              </TableCell>
              <TableCell></TableCell>
            </TableRow>
          ) : (
            <></>
          )}
        </>
      )}
    </>
  );
}

export default ProjectsTotal;
