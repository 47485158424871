import {
  Box,
  Button,
  Grid2,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { changePassword } from "../../../../actions/authActions";

const CssTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: "#3D6076",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#3D6076",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      boxShadow: "0px -2px 10px 0px rgba(0,0,0,0.55) inset",
      borderRadius: "1rem",
      borderColor: "#3D6076",
    },
    "&:hover fieldset": {
      borderColor: "#3D6076",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#3D6076",
    },
  },
});

function ChangePasswordForm() {
  const user = useSelector((state) => state.auth.me);

  const [state, setState] = useState({ currentPassword: "", newpassword: "" });

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [currentPassError, setCurrentPassError] = useState("");
  const [passwordError, setPasswordError] = useState("");

  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () =>
    setShowPassword((showPassword) => !showPassword);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const resetErrors = () => {
    setTimeout(() => {
      setCurrentPassError("");
      setPasswordError("");
    }, 2000);
  };

  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (state.currentPassword === "" || state.currentPassword.length < 6) {
      setCurrentPassError("Contraseña no valida");
    }
    if (state.newpassword === "" || state.newpassword.length < 6) {
      setPasswordError("Constraseña no valida");
    }
    resetErrors();
    if (
      state.newpassword === "" ||
      state.currentPassword === "" ||
      state.newpassword.length < 6 ||
      state.currentPassword.length < 6
    ) {
      return;
    }
    dispatch(
      changePassword({
        currentPassword: state.currentPassword,
        newPassword: state.newpassword,
        callback: () => {
          handleSuccess();
        },
      })
    );
  };

  const handleSuccess = () => {
    navigate(-1);
  };

  return (
    <Grid2 container justifyContent={"center"}>
      <Grid2 size={{ xs: 12, sm: 10, md: 10, lg: 10 }}>
        <Typography variant="h5" component={"h2"} fontWeight={"bold"}>
          Usuario
        </Typography>
        <Typography variant="h6" component={"p"} color="primary" mb={2}>
          {user?.user ?? "-"}
        </Typography>
        <Box component={"form"} onSubmit={handleSubmit}>
          <Typography variant="p">Contraseña actual</Typography>
          <CssTextField
            disabled={false}
            placeholder="Contraseña"
            type={showPassword ? "text" : "password"}
            name="currentPassword"
            value={state.password}
            onChange={handleChange}
            error={currentPassError !== "" ? true : false}
            helperText={currentPassError !== "" ? currentPassError : ""}
            variant="outlined"
            slotProps={{
              input: {
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              },
            }}
          />
          <Typography variant="p">Contraseña nueva</Typography>
          <CssTextField
            disabled={false}
            placeholder="Nueva contraseña"
            type={showPassword ? "text" : "password"}
            name="newpassword"
            value={state.password}
            onChange={handleChange}
            error={passwordError !== "" ? true : false}
            helperText={passwordError !== "" ? passwordError : ""}
            variant="outlined"
            slotProps={{
              input: {
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              },
            }}
          />
          <Button type="submit" fullWidth sx={{ py: 1.5, mt: 3 }}>
            Cambiar contraseña
          </Button>
        </Box>
      </Grid2>
    </Grid2>
  );
}

export default ChangePasswordForm;
