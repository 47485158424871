import {
  Box,
  Paper,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableBody,
  Link,
} from "@mui/material";
import React, { useState } from "react";
import ReportsFilter from "./ReportsFilter";

const { REACT_APP_HOST_URL } = process.env;

function ReportsSection({ data, investments }) {
  const [localReports, setLocalReports] = useState([...data]);

  console.log({ data, investments });

  const hoursToAdd = 6 * 60 * 60 * 1000;

  return (
    <Box my={2}>
      <ReportsFilter
        localTotalReports={data}
        investments={investments}
        setLocalReports={setLocalReports}
      />
      <Box component={"section"} my={2}>
        <TableContainer
          component={Paper}
          style={{ backgroundColor: "#ffffff", borderRadius: "0.75rem" }}
        >
          <Table>
            <TableHead sx={{ backgroundColor: "#3D6076", borderRadius: 10 }}>
              <TableRow>
                <TableCell style={{ minWidth: 120 }} align="start">
                  Nombre
                </TableCell>
                <TableCell style={{ minWidth: 120 }} align="center">
                  Proyecto
                </TableCell>
                <TableCell style={{ minWidth: 120 }} align="center">
                  Periodo
                </TableCell>
                <TableCell style={{ minWidth: 120 }} align="center">
                  Fecha
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody style={{ maxHeight: "150px", overflowY: "auto" }}>
              {localReports.map((report, index) => (
                <TableRow key={`report-${report._id}-${index}`}>
                  <TableCell align="start">
                    {!!report?.path ? (
                      <Link
                        sx={{ color: "#3D6076" }}
                        target="_blank"
                        href={`${REACT_APP_HOST_URL}/images/reports/${
                          report?.path ?? ""
                        }`}
                      >
                        {report.name}
                      </Link>
                    ) : (
                      <Box>Sin reporte</Box>
                    )}
                  </TableCell>
                  <TableCell align="start">
                    {investments.find(
                      (invest) => invest.projectRef._id === report.projectRef
                    )?.projectRef?.name ?? "Inversion"}
                  </TableCell>
                  <TableCell align="center">{report.period}</TableCell>
                  <TableCell align="center">
                    {new Date(
                      new Date(report.date).setTime(
                        new Date(report.date).getTime() + hoursToAdd
                      )
                    ).toLocaleDateString("en-GB", {
                      month: "2-digit",
                      day: "2-digit",
                      year: "numeric",
                    })}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
}

export default ReportsSection;
