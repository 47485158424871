import { Box, Container, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getInvestmentsReportsList } from "../../../../actions/investmentsReportsActions";
import ReportsSection from "../ReportsSection";

function InformativeReportsPage() {
  const dispatch = useDispatch();
  const investments = useSelector((state) => state.investments.list);
  const allReports = useSelector((state) => state.investmentReports.list);
  const isLoading = useSelector((state) => state.loading.isLoading);

  useEffect(() => {
    const query = { page: 1 };
    dispatch(getInvestmentsReportsList({ query: query, type: "informativo" }));
  }, []);
  return (
    <Container maxWidth="xl">
      <Typography variant="h3" fontWeight={"bold"} my={2}>
        Reportes Informativos
      </Typography>
      {isLoading ? (
        <Box>
          <Typography>Cargando...</Typography>
        </Box>
      ) : (
        <ReportsSection investments={investments} data={allReports} />
      )}
    </Container>
  );
}

export default InformativeReportsPage;
