import { AppBar, Toolbar, Typography } from "@mui/material";
import { useSelector } from "react-redux";

const drawerWidth = 180;

export const ProjectAppbar = (props) => {
  const name = useSelector((state) => state.auth.me);

  return (
    <>
      <AppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
        }}
      >
        <Toolbar>
          <Typography
            variant="h5"
            color="inherit"
            component="div"
            sx={{ flexGrow: 1, display: { xs: "none", sm: "block" } }}
          >
            Hola {name.name ?? "IDE Fund Admin"}
          </Typography>
          {/*
         
          <Button variant="text" onClick={() => props.onOpen("reports")}>
            <Typography
              variant="span"
              color="white"
              sx={{
                flexGrow: 1,
                display: { xs: "none", sm: "block" },
                textDecoration: "underline",
                textUnderlineOffset: ".5rem",
              }}
            >
              Reportes Informativos
            </Typography>
          </Button>
          <Box>
            <Button variant="text" onClick={() => props.onOpen("finance")}>
              <Typography
                variant="subtitle2"
                color="white"
                component="span"
                sx={{
                  flexGrow: 1,
                  display: { xs: "none", sm: "block" },
                  textDecoration: "underline",
                  textUnderlineOffset: ".5rem",
                }}
              >
                Información Financiera
              </Typography>
            </Button>
          </Box>
           */}
        </Toolbar>
      </AppBar>
    </>
  );
};
