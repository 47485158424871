import {
  Paper,
  TableContainer,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
} from "@mui/material";
import ProjectsListItem from "./ProjectsListItem";
import ProjectsTotal from "./ProjectsTotal";

const ProjectsListTable = (props) => {
  return (
    <TableContainer
      component={Paper}
      style={{ backgroundColor: "#ffffff", borderRadius: "0.75rem" }}
    >
      <Table>
        <TableHead sx={{ backgroundColor: "#3D6076" }}>
          <TableRow sx={{ borderBottom: "2px solid" }}>
            <TableCell sx={{ textAlign: "center" }}>Proyecto</TableCell>
            <TableCell sx={{ textAlign: "center" }}>Nombre</TableCell>
            <TableCell sx={{ textAlign: "center" }}>
              Fecha de inversión
            </TableCell>
            <TableCell sx={{ textAlign: "center" }}>Divisa</TableCell>
            <TableCell sx={{ textAlign: "center" }}>
              Monto de Inversión
            </TableCell>
            <TableCell sx={{ textAlign: "center" }}>
              Participación en el activo
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.projects?.map((project) => (
            <ProjectsListItem key={project._id} {...project} />
          ))}
          <ProjectsTotal projects={props.projects} />
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ProjectsListTable;
