import { useSelector } from "react-redux";
import {
  Box,
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { currencyFormatter } from "../../../../utils/formatUtils";

const { REACT_APP_HOST_URL } = process.env;

const ProjectDetailsDistributions = (props) => {
  const distributions = useSelector((state) => state.distributions.list);
  const reports = useSelector((state) => state.reports.investor);

  const hoursToAdd = 6 * 60 * 60 * 1000;

  return (
    <>
      {distributions.length > 0 ? (
        <Box my={2}>
          <Typography variant="h5" mb={1}>
            Pago de distribuciones
          </Typography>
          <TableContainer
            component={Paper}
            style={{ backgroundColor: "#ffffff", borderRadius: "0.75rem" }}
          >
            <Table>
              <TableHead sx={{ backgroundColor: "#3D6076", borderRadius: 10 }}>
                <TableRow>
                  <TableCell style={{ minWidth: 120 }} align="center">
                    Fecha
                  </TableCell>
                  <TableCell style={{ minWidth: 120 }} align="center">
                    Periodo
                  </TableCell>
                  <TableCell style={{ minWidth: 120 }} align="center">
                    Distribución Neta
                  </TableCell>
                  <TableCell style={{ minWidth: 120 }} align="center">
                    Distribución Neta Acumulada
                  </TableCell>
                  <TableCell style={{ minWidth: 120 }} align="center">
                    Reportes
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {distributions.map((distribution) => (
                  <TableRow key={distribution._id}>
                    <TableCell style={{ minWidth: 120 }} align="center">
                      {new Date(
                        new Date(distribution.date).setTime(
                          new Date(distribution.date).getTime() + hoursToAdd
                        )
                      ).toLocaleDateString("en-GB", {
                        month: "2-digit",
                        day: "2-digit",
                        year: "numeric",
                      })}
                    </TableCell>
                    <TableCell style={{ minWidth: 120 }} align="center">
                      {distribution.period}
                    </TableCell>
                    <TableCell style={{ minWidth: 120 }} align="center">
                      {
                        currencyFormatter
                          .format(distribution.netDistribution)
                          .split(".")[0]
                      }
                    </TableCell>
                    <TableCell style={{ minWidth: 120 }} align="center">
                      {currencyFormatter.format(distribution.netAccumulated)}
                    </TableCell>
                    <TableCell style={{ minWidth: 120 }} align="center">
                      {reports.find(
                        (r) => r?.distributionRef === distribution._id
                      ) ? (
                        <Link
                          sx={{ color: "#3D6076" }}
                          href={`${REACT_APP_HOST_URL}/images/reports/${
                            reports.find(
                              (r) => r?.distributionRef === distribution._id
                            ).path
                          }`}
                        >
                          Ver Reporte
                        </Link>
                      ) : (
                        "Sin reporte"
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      ) : null}
    </>
  );
};

export default ProjectDetailsDistributions;
